import { type CallbotRecord, type CallbotUser } from "@/types/callbotRecord";

/*export const defaultData: CallbotRecord[] = [
  {
    id: "b5c3f404-9ac3-495a-b149-cb6486240349",
    userExecutions: [
      {
        id: 417,
        execution_id: "b5c3f404-9ac3-495a-b149-cb6486240349",
        user: {
          id: 2,
          zoho_id: "Z43434",
          type_id: 11,
          status_id: 1,
          first_name: "Axl",
          last_name: "Rose",
          email: null,
          date_of_birth: "1980-01-01",
          phone: "12345679",
          created_at: "2023-04-14T06:16:57.877Z",
          updated_at: "2023-04-14T06:16:57.877Z",
        },
        user_id: 2,
        status_id: 3,
        disposition_id: null,
        notes: null,
        created_at: "2023-04-19T19:11:19.213Z",
        updated_at: "2023-04-19T19:11:19.213Z",
        last_action: null,
        last_action_user_id: null,
      },
    ],
    contact_id: "3887925001722894152",
    first_name: "Anthony",
    last_name: "Glass",
    email: "aglass18@yahoo.com",
    ssn: "111111111",
    date_of_birth: "1969-05-01",
    balance: "2029.75",
    baseline_balance: "0.00",
    retries: 0,
    status: "READY_TO_REVIEW",
    status_info: "",
    twilio_execution_sid: "",
    twilio_flow_sid: "",
    filing_key: "G6742_Glass_Anthony",
    transcript: "",
    notes: null,
    completed_date: "2023-04-16T05:14:25.106Z",
    created_at: "2023-04-16T05:11:23.839Z",
    updated_at: "2023-04-16T05:14:25.110Z",
  },
  {
    id: "b5c3f404-9ac3-495a-b149-cb6486233349",
    userExecutions: [
      {
        id: 417,
        execution_id: "b5c3f404-9ac3-495a-b149-cb6486240349",
        user: {
          id: 2,
          zoho_id: "Z43434",
          type_id: 11,
          status_id: 1,
          first_name: "Axl",
          last_name: "Rose",
          email: null,
          date_of_birth: "1980-01-01",
          phone: "12345679",
          created_at: "2023-04-14T06:16:57.877Z",
          updated_at: "2023-04-14T06:16:57.877Z",
        },
        user_id: 2,
        status_id: 3,
        disposition_id: null,
        notes: null,
        created_at: "2023-04-19T19:11:19.213Z",
        updated_at: "2023-04-19T19:11:19.213Z",
      },
    ],
    contact_id: "3887925001722894152",
    first_name: "Doe",
    last_name: "John",
    email: "aglass18@yahoo.com",
    ssn: "111111111",
    date_of_birth: "1969-05-01",
    balance: "2029.75",
    baseline_balance: "0.00",
    retries: 0,
    status: "READY_TO_REVIEW",
    status_info: "",
    twilio_execution_sid: "",
    twilio_flow_sid: "",
    filing_key: "H3424_John_Doe",
    transcript: "",
    notes: null,
    completed_date: "2023-04-16T05:14:25.106Z",
    created_at: "2023-04-16T05:11:23.839Z",
    updated_at: "2023-04-16T05:14:25.110Z",
  },
];*/

export const defaultAssignData: CallbotUser[] = [
  {
    id: 1,
    zoho_id: "W9108_John_Doe",
    type_id: 1,
    status_id: 1,
    first_name: "John",
    last_name: "Doe",
    email: "john.doe@gmail.com",
    date_of_birth: "string",
    phone: "string",
    created_at: "2023-04-14T06:16:57.877Z",
    updated_at: "2023-04-14T06:16:57.877Z",
  },
  {
    id: 2,
    zoho_id: "W4508_Mark_Smith",
    type_id: 2,
    status_id: 2,
    first_name: "Mark",
    last_name: "Smith",
    email: "mark.smith@gmail.com",
    date_of_birth: "string",
    phone: "string",
    created_at: "2023-04-14T06:16:57.877Z",
    updated_at: "2023-04-14T06:16:57.877Z",
  },
];

export const defaultDispisitionModel = {
  already_invoiced: 0,
  appeal_follow_up: 0,
  baseline: 0,
  closed_out: 0,
  cola: 0,
  dependent: 0,
  email_follow_up: 0,
  invoiced: 0,
  invoiced_appeal: 0,
  medically_retired: 0,
  new: 0,
  not_tmus: 0,
  over_7k: 0,
  pension_phch31gi_bill: 0,
  ptr_pts: 0,
  retired_lt_50: 0,
  retro_follow_up: 0,
  tdiu_temp_conv: 0,
};

export const defaultCallsModel = {
  "Total Calls": 0,
  Failed: 0,
  "Baseline Not Met": 0,
  "Invoice Opportunity Created": 0,
};

export const defaultTasksModel = {
  closed: "",
  created: "",
  invoice_pending: "",
  ready_for_review: "",
  review_in_progress: "",
};
