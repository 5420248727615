/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { type ColumnDef } from "@tanstack/react-table";
import { type DispositionTable } from "@/types/dashboard";
import React from "react";
import { HeaderAlign } from "@/types/table";

export const defaultColumns: ColumnDef<DispositionTable>[] = [
  {
    accessorKey: "name",
    id: "name",
    header: "Name",
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: "total",
    id: "total",
    header: "Total",
    meta: { headerAlign: HeaderAlign.RIGHT },
    cell: (info) => (
      <div className="text-right">{info.getValue() as string}</div>
    ),
  },
];
