import { type NextPage } from "next";
import Head from "next/head";
import Dashboard from "@/components/dashboard";
import { useState } from "react";
import { type DatePickerFilter } from "@/components/shared/customDateRangePicker/types";
import CustomDateRangePicker from "@/components/shared/customDateRangePicker/customDateRangePicker";

const Home: NextPage = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const [selectedDateRange, setSelectedDateRange] = useState<DatePickerFilter>({
    startDate: firstDayOfMonth,
    endDate: currentDate,
    key: "selection",
  });

  return (
    <>
      <Head>
        <title>Invoicing Workflow Application</title>
        <meta name="description" content="Generated by create-t3-app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className=" h-full w-full p-8">
        <div className="flex w-full justify-between">
          <p className="w-1/2 text-xl font-semibold tracking-wide">Dashboard</p>
          <CustomDateRangePicker
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
          />
        </div>
        <Dashboard selectedDateRange={selectedDateRange} />
      </div>
    </>
  );
};

export default Home;
